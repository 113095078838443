<template>
  <b-card>
    <b-col class="flex-column">
      <h4 class="title" style="display: flex;">
        <svgicon class="icon mr-2" icon="item-languages" width="20" height="20" />
        <span>{{t('Language selection')}}</span>
      </h4>
      <p class="mb-4">{{ t('Select the language for the notifications') }}</p>
      <div class="px-3 row justify-content-between">
        <div class="col-md-9 col-xs-12 flex-buttons">
          <el-select
            :value="selectedLang"
            style="min-width: 150px"
            placeholder="Select the language"
            @input="onLanguage($event, 'type')"
            class="select-primary"
          >
            <el-option
              v-for="option in langOptions"
              :key="option.value"
              :label="option.name"
              :value="option.value"
              :disabled="option.disabled"
            >
            </el-option>
          </el-select>
          <n-button
            @click.native="emitLangModal"
            type="primary"
            style="min-width: 110px; max-width: 140px"
            size="md"
            :visible_for_permissions='[{actions: ["POST"], path: "/notifications/languages-configuration"}]'
            round
            block
          >
            <i slot="label" />
            <svgicon style="margin-right: 3px" class="icon" icon="icon-new" />
            {{t('New')}}
          </n-button>
          <n-button
            @click.native="onResetAll"
            style="min-width: 150px; max-width: 180px"
            type="primary"
            size="md"
            round
            :visible_for_permissions='[{actions: ["PATCH"], path: "/notifications/languages-configuration/.*"}]'
            block
            v-text="getLocalizedText('Reset all')"
          />
        </div>
        <div class="col-md-3 col-xs-12 align-to-right">
          <n-button
            v-if="langOptions[selectedLang]"
            @click.native="emitDeleteLanguageModal"
            :disabled="
              langOptions[selectedLang].internal ||
              selectedLang === defaultLang
            "
            style="max-width: 220px"
            type="danger"
            size="md"
            round
            :visible_for_permissions='[{actions: ["DELETE"], path: "/notifications/languages-configuration/.*"}]'
            block
            v-text="getLocalizedText('Delete Language')"
          />
        </div>
      </div>
    </b-col>
    <b-modal
      id="modal-new"
      ref="modal"
      :title="t('New Notification language')"
      @ok="handleOk" :ok-title="t('accept')" :cancel-title="t('cancel')"
    >
      <form @submit.prevent="handleOk">
        <div class="form-group has-label">
          <label>{{t('Select language')}}</label>
          <el-autocomplete
            :fetch-suggestions="querySearch"
            class="autocomplete-input inline-input no-border-autocomplete"
            popper-class="autocomplete-dropdown"
            v-validate="{ required: true }"
            size="large"
            :placeholder="this.t('New language')"
            v-model="newLanguage.name"
            @select="onAutoCompleteLang"
          >
            <template slot-scope="{ item }">
              <div>{{ item.name }}</div>
            </template>
          </el-autocomplete>
        </div>
      </form>
    </b-modal>
    <BasicModal
      @onFirstBtn="onDeleteLanguage"
      @onSecBtn="showDeleteLanguageModal = false"
      :firstBtnTxt="this.t('Remove')"
      :secBtnTxt="this.t('Cancel')"
      :txt="deleteLanguageModal"
      :showModal="showDeleteLanguageModal"
      :maxWidth="450"
    ></BasicModal>
    <BasicModal
      @onFirstBtn="showMaxLangsModal = false"
      :firstBtnTxt="this.t('ok')"
      :showSecBtn="false"
      :txt="maxLangModalTxt"
      :showModal="showMaxLangsModal"
      :maxWidth="450"
    ></BasicModal>
  </b-card>
</template>
<script>
import { Autocomplete } from "element-ui";
import BasicModal from "@/components/Modals/BasicModal";
import languagesList from '@/mixins/languagesList';

export default {
  mixins: [languagesList],
  components: { BasicModal, "el-autocomplete": Autocomplete },
  name: "LanaguageSelection",
  props: {
    langOptions: {
      type: Object,
      required: true,
    },
    maxLanguages: {
      type: Number,
    },
    defaultLang: {
      type: String,
    },
  },
  data() {
    return {
      selectedLang: "en-US",
      lastLang: "en-US",
      newLanguage: { name: null, value: null, internal: false },
      showDeleteLanguageModal: false,
      deleteLanguageModal: this.t('Are you sure you want to delete the language'),
      showMaxLangsModal: false,
      maxLangModalTxt: this.t('You have reached to the maximum capacity of languages, you have to delete a language in order to add a new one'),
    };
  },
  created() {
    this.selectedLang = this.defaultLang
    this.lastLang = this.defaultLang
  },
  methods: {
    onResetAll() {
      this.$emit("onResetAll");
    },
    onLanguage(lang) {
      this.selectedLang = lang;
      this.$emit("onLangChanged", lang);
    },
    onUpdateLastLang(lastLang) {
      this.lastLang = lastLang;
    },
    updateSelectedLang(lang) {
      if (lang) this.lastLang = lang;
      this.selectedLang = this.lastLang;
    },
    emitLangModal() {
      if (Object.keys(this.langOptions).length >= this.maxLanguages + 1) {
        this.showMaxLangsModal = true;
      } else {
        this.newLanguage = { name: null, value: null };
        this.$bvModal.show("modal-new");
      }
    },
    onAutoCompleteLang(lang) {
      this.newLanguage = lang;
    },
    handleOk() {
      this.$emit("onNewLanguage", this.newLanguage.value);
    },
    emitDeleteLanguageModal() {
      if (this.selectedLang !== "en-US") this.showDeleteLanguageModal = true;
    },
    onDeleteLanguage() {
      this.$emit("onDeleteLanguage");
      this.showDeleteLanguageModal = false;
    },
    querySearch(queryString, cb) {
      let results = queryString
        ? this.newLanguagesList.filter((option) => {
            return (
              option.name.toLowerCase().includes(queryString.toLowerCase()) ||
              option.value.toLowerCase().includes(queryString.toLowerCase())
            );
          })
        : this.newLanguagesList;
      cb(results);
    },
    getLocalizedText(text) {
      return this.t(text);
    }
  },
  computed: {
    newLanguagesList() {
      const results = this.supported_languages_list.reduce((filteredLanguages, option) => {
        if (!this.langOptions[option.language_id]) {
          let filteredLanguage = {
            name: option.language_name,
            value: option.language_id
          };
          filteredLanguages.push(filteredLanguage);
        }
        return filteredLanguages;
      }, []);
      return results;
    }
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
  .card {
    margin-bottom: 0;
  }
  ::v-deep .autocomplete-input input {
    color: black !important;
  }
  .flex-buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
  }
  .align-to-right {
    button {
      margin-left: auto;
    }
  }
</style>

<template>
  <div>
    <div v-show="notifications.platforms.sms.enabled" class="mt-1">
      <h6 class="title">{{t('SMS')}}</h6>
      <fg-input
        class="fow br5"
        @input="onInputMsg"
        textarea
        :rows="'3'"
        name="body"
        :placeholder="t('body')"
        v-validate="validations.max512char"
        :error="getError('body')"
        ref="body"
        :borderColor="'#E3E3E3'"
        v-model="mutableMessages.sms.body"
        :maxlength="512"
        :showCharsLeft="true"
        :showIcon="false"
        :showRemainsChar="true"
      ></fg-input>
    </div>
    <div v-show="notifications.platforms.email.enabled" class="mt-4">
      <h6 class="title">{{t('Email')}}</h6>
      <fg-input
        class="fow br5 mb-1 subject"
        @input="onInputMsg"
        name="subject"
        :placeholder="t('subject')"
        v-validate="validations.max80char"
        :error="getError('subject')"
        ref="body"
        :borderColor="'#E3E3E3'"
        :customStyle="'border-bottom: unset border-radius: 5px 5px 0 0;'"
        v-model="mutableMessages.email.subject"
        :maxlength="80"
        :showIcon="false"
        :showRemainsChar="true"
      />

      <fg-input
        class="fow mb-0 br5"
        @input="onInputMsg('emailBody')"
        textarea
        :rows="'3'"
        name="body"
        :placeholder="t('body')"
        v-validate="validations.max512char"
        :error="getError('body')"
        ref="body"
        :borderColor="'#E3E3E3'"
        v-model="mutableMessages.email.body"
        :maxlength="512"
        :showRemainsChar="true"
        :showIcon="false"
      />
    </div>
    <div v-show="notifications.platforms.push_app.enabled" class="mt-4">
      <h6 class="title">{{t('Title')}}</h6>
      <fg-input
        class="fow mb-3 push-app_title"
        @input="onInputMsg"
        name="title"
        :placeholder="t('Title')"
        ref="title"
        :borderColor="'#E3E3E3'"
        v-model="mutableMessages.push_app.title"
      ></fg-input>
      <h6 class="title">{{t('Content')}}</h6>
      <fg-input
        class="fow br5"
        @input="onInputMsg"
        textarea
        :rows="'3'"
        name="body"
        :placeholder="t('body')"
        v-validate="validations.max512char"
        :error="getError('body')"
        ref="body"
        :borderColor="'#E3E3E3'"
        v-model="mutableMessages.push_app.body"
        :maxlength="512"
        :showCharsLeft="true"
        :showIcon="false"
        :showRemainsChar="true"
      ></fg-input>
    </div>
    <template v-if="showB2B">
      <h6 class="title mt-4">{{t('B2B')}}</h6>
      <fg-input
        class="fow br5"
        @input="onInputMsg"
        textarea
        :rows="'3'"
        name="body"
        :placeholder="t('body')"
        v-validate="validations.max512char"
        :error="getError('body')"
        ref="body"
        :borderColor="'#E3E3E3'"
        v-model="mutableMessages.b2b.body"
        :maxlength="512"
        :showCharsLeft="true"
        :showIcon="false"
        :showRemainsChar="true"
      ></fg-input>
    </template>

    <div :key="idx" v-for="(value, confName, idx) in mutableAdvancedData">
      <h6 class="title mt-4">{{ t(translateHandler(confName, "_", " ")) }}</h6>
      <fg-input
        class="fow br5 mb-1 subject"
        :inputType="'number'"
        @input="onAdvancedConf(+$event, confName)"
        :name="`${confName} ${idx}`"
        :ref="`${confName} ${idx}`"
        :error="validationErrors[confName]"
        :borderColor="'#E3E3E3'"
        :customStyle="'border-bottom: unset border-radius: 5px 5px 0 0;'"
        v-model.number="mutableAdvancedData[confName]"
        :showIcon="false"
        :showRemainsChar="true"
      />
    </div>
  </div>
</template>


<script>
import FormMixin from "@/mixins/FormMixin";
import General from "@/mixins/General";
import { mapState } from "vuex";
import schemaValidation from "@/mixins/schemaValidation";

export default {
  name: "Messages",
  components: {},
  props: {
    serviceName: {
      type: String,
      required: true,
    },
    subServiceName: {
      type: String,
      required: true,
    },
    advancedData: {
      type: Object,
      defualt: () => null,
    },
    showB2B: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [FormMixin, General, schemaValidation],
  data() {
    return {
      mutableMessages: { email: {}, sms: {}, push_app: {}, b2b: {} },
      mutableAdvancedData: null,
    };
  },
  created() {
    this.reset();
  },
  methods: {
    onInputMsg(inputLabel) {
      if (inputLabel === "emailBody") {
        this.mutableMessages.email.html_body = `<p>${this.mutableMessages.email.body}</p>`;
      }
      this.$emit("onMsgUpdate", this.mutableMessages);
    },
    onAdvancedConf(value, confName) {
      this.validation(
        this.mutableAdvancedData,
        this.schema.properties[this.serviceName].properties[
          this.subServiceName
        ],
        confName
      );

      this.$emit(
        "onAdvacnedConfUpdate",
        this.subServiceName,
        this.mutableAdvancedData
      );
    },
    placeholder(propName) {
      return this.translateHandler(propName, "_", " ");
    },
    reset() {
      let configuration = this.notifications.allConfigurations[
        this.selectedLang
        ];
      if (!configuration && this.selectedLang.includes("-")) {
        configuration = _.flow([
          Object.entries,
          arr => (arr.filter(([key, value]) => key.includes(this.selectedLang.slice((this.selectedLang.indexOf("-") + 1), this.selectedLang.length))))[0][1]
        ])(this.notifications.allConfigurations);
      }
      if (
        configuration[this.serviceName] &&
        configuration[this.serviceName][this.subServiceName]
      ) {
        const configurationClone = _.cloneDeep(
          configuration[this.serviceName][this.subServiceName]
        );

        if(!configurationClone.push_app.title) {
          configurationClone.push_app.title = this.placeholder(this.subServiceName, "_", " ");
        }

        this.mutableMessages = configurationClone;
      } else {
        this.mutableMessages = {
          email: {},
          sms: {},
          push_app: {},
          b2b: {}
        }
      }

      this.mutableAdvancedData = _.cloneDeep(this.advancedData);
      delete this.mutableAdvancedData.enabled;
      delete this.mutableAdvancedData.min_interval;
    }
  },
  computed: {
    ...mapState("ispServices/notifications", ["notifications", "selectedLang"]),
  },
};
</script>

<style lang="scss">
 .push-app_title input{
   text-transform: capitalize;
 }
</style>

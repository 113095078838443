<template>
  <div>
    <page-title-card
      :page-title="t('title')"
      :page-description="t('description')"
    >
      <template #button>
        <n-button
          :disabled="validation_errors.length > 0"
          type="primary"
          size="md"
          round
          block
          @click.native="onEmitSaveModal"
          v-text="getLocalizedText('Save')"
          :visible_for_permissions='[{actions: ["PUT"], path: "/notifications/.*"}]'
          v-b-modal.confirm-update
        />
      </template>
    </page-title-card>
    <Platforms
      style="margin-bottom: 30px"
      v-if="platforms"
      :platforms="platforms"
      :gatewayState="gatewayState"
    ></Platforms>
    <LanaguageSelection
      v-if="defaultLang && Object.keys(langOptions)"
      ref="LanaguageSelection"
      @onResetAll="showResetAlertModal = true"
      @onLangChanged="onLangChanged"
      @onNewLanguage="onNewLanguage"
      @onDeleteLanguage="onDeleteLanguage"
      style="margin-bottom: 30px"
      :langOptions="langOptions"
      :maxLanguages="maxLanguages"
      :defaultLang="defaultLang"
    ></LanaguageSelection>
    <ServicesList
      v-if="defaultServices"
      :defaultServices="defaultServices"
    ></ServicesList>
    <BasicModal
      @onFirstBtn="onCancel"
      @onSecBtn="onDiscard"
      :firstBtnTxt="this.t('Keep editing')"
      :secBtnTxt="this.t('Discard')"
      :txt="langAlertModalTxt"
      :showModal="showLangAlertModal"
      :maxWidth="395"
    ></BasicModal>
    <BasicModal
      @onFirstBtn="onResetAll"
      @onSecBtn="showResetAlertModal = false"
      :firstBtnTxt="this.t('Reset all')"
      :secBtnTxt="this.t('Cancel')"
      :txt="ResetTxtModal"
      :showModal="showResetAlertModal"
      :maxWidth="450"
    ></BasicModal>
    <BasicModal
      @onFirstBtn="onSave"
      @onSecBtn="showModal = false"
      :showModal="showModal"
      :txt="this.t('notifications.save-text')"
    ></BasicModal>
  </div>
</template>
<script>
import TopCard from "@/components/AsmConfigurations/TopCard";
import Platforms from "@/components/GeneralManagement/Notifications/Platforms";
import LanaguageSelection from "@/components/GeneralManagement/Notifications/LanaguageSelection";
import ServicesList from "@/components/GeneralManagement/Notifications/ServicesList";
import BasicModal from "@/components/Modals/BasicModal";
import { mapActions, mapState, mapGetters } from "vuex";
import schemaValidation from "@/mixins/schemaValidation";
import PageTitleCard from "../../components/AsmConfigurations/PageTitleCard";

export default {
  name: "notifications",
  components: {
    Platforms,
    ServicesList,
    TopCard,
    BasicModal,
    LanaguageSelection,
    PageTitleCard
  },
  props: {},
  mixins: [schemaValidation],
  data() {
    return {
      platforms: null,
      defaultServices: null,
      showModal: false,
      showResetAlertModal: false,
      showLangAlertModal: false,
      requestedLang: null,
      langAlertModalTxt:
        this.t('In order to change the language, you have to save or discard the changes'),
      ResetTxtModal:
        this.t('Are you sure you want to reset the notification texts by loading the default ones?'),
    };
  },
  async created() {
    const jsonschema = "ISPNotificationsAdvanced";
    await Promise.allSettled([
      this.choseSchema(jsonschema),
      this.getNotificationsPlatforms(),
      this.getNotificationsAllConfiguration(),
      this.checkIfSmsGatewayEnabled(),
      this.checkIfEmailGatewayEnabled(),
    ]);
    await this.getNotificationsAdvancedConfiguration();
    this.$store.commit({
      type: "ispServices/notifications/updateSelectedLang",
      lang: this.defaultLang,
    });
    this.platforms = this.notifications.platforms;
    this.defaultServices = this.services;
  },
  methods: {
    ...mapActions("ispServices/notifications", [
      "getNotificationsAllConfiguration",
      "getNotificationsAdvancedConfiguration",
      "getNotificationsPlatforms",
      "updateNotificationsPlatforms",
      "updateNotificationsAdvancedConfiguration",
      "updateNotificationsConfiguration",
      "createNewNotificationLanguage",
      "deleteNotificationLanguage",
      "resetLanguage",
      "checkIfSmsGatewayEnabled",
      "checkIfEmailGatewayEnabled",
    ]),
    ...mapActions('settings', ['setIsFormDirty']),
    reload() {
      this.platforms = null;
      this.defaultServices = null;
      setTimeout(() => {
        this.platforms = this.notifications.platforms;
        this.defaultServices = this.services;
      }, 300);
    },
    async onResetAll() {
      await this.resetLanguage({ all: true });
      await Promise.all([
        this.getNotificationsPlatforms(),
        this.getNotificationsAllConfiguration(),
        this.getNotificationsAdvancedConfiguration(),
      ]);
      const lang = this.requestedLang ? this.requestedLang : this.defaultLang;
      this.$refs.LanaguageSelection.onUpdateLastLang(lang);
      this.$store.commit({
        type: "ispServices/notifications/updateSelectedLang",
        lang
      });
      this.reload();
      this.showResetAlertModal = false;
    },
    onLangChanged(lang) {
      this.requestedLang = lang;
      if (this.isFormDirty) {
        this.showLangAlertModal = true;
      } else {
        this.changeLang();
      }
    },
    onDiscard() {
      this.setIsFormDirty(false);
      this.$store.commit({
        type: "ispServices/notifications/resetAllNotificationsConfigurations",
      });
      this.changeLang();
    },
    onCancel() {
      this.$refs.LanaguageSelection.updateSelectedLang();
      this.showLangAlertModal = false;
    },
    changeLang() {
      this.$refs.LanaguageSelection.onUpdateLastLang(this.requestedLang);
      this.$store.commit({
        type: "ispServices/notifications/updateSelectedLang",
        lang: this.requestedLang,
      });
      this.showLangAlertModal = false;
      this.reload();
    },
    onNewLanguage(newLanguage) {
      this.createNewNotificationLanguage({ newLanguage });
    },
    async onDeleteLanguage() {
      await this.deleteNotificationLanguage();
      this.$refs.LanaguageSelection.updateSelectedLang(this.defaultLang);
      this.$store.commit({
        type: "ispServices/notifications/updateSelectedLang",
        lang: this.defaultLang,
      });
      this.reload();
    },
    onEmitSaveModal() {
      if (!this.validation_errors.length) {
        this.showModal = true;
      }
    },
    onSave() {
      this.setIsFormDirty(false);
      this.updateNotificationsPlatforms();
      this.updateNotificationsConfiguration();
      this.updateNotificationsAdvancedConfiguration();
      this.showModal = false;
    },
    getLocalizedText(text) {
      return this.t(text);
    }
  },
  computed: {
    ...mapState("settings",["isFormDirty"]),
    ...mapState("ispServices/notifications", [
      "notifications",
      "defaultLang",
      "gatewayState"
    ]),
    ...mapGetters("ispServices/notifications", [
      "langOptions",
      "services",
      "maxLanguages",
    ]),
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
</style>

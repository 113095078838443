<template>
  <b-card>
    <b-col class="flex-column">
      <h4 class="title" style="display: flex;">
        <svgicon class="icon mr-2" icon="item-platforms" width="20" height="20" />
        <span>{{t('Platforms')}}</span>
      </h4>
      <p class="mb-4">{{ t(help_text) }}</p>
      <div class="px-3 row">
        <div
          class="d-flex"
          style="margin-right: 60px; flex-direction: column"
          v-for="(plat, platName) in platformsList"
          :key="platName"
        >
          <div class="d-flex" style="width: 100%">
            <p
              class="mr-3 mb-0 text-capitalize"
              :class="'platform-name__' + platName"
              >
              {{ t(platName.replace("_", " ")) }}
            </p>
            <n-switch
              :disabled="!gatewayState[platName] && platName != 'push_app'"
              v-model="platformsList[platName].enabled"
              @input="togglePlatform($event, platName)"
              :visible_for_permissions='[{actions: ["PUT"], path: "/notifications/.*"}]'
              class="mt-0 mb-0"
              color="allot"
            />
          </div>
          <div
            class="text-danger invalid-feedback"
            style="display: block; white-space: normal; line-height: normal"
            v-if="!gatewayState[platName] && platName != 'push_app'"
          >
          {{ `No ${platName} ` + t('gateway defined in the system') }}
          </div>
        </div>
      </div>
    </b-col>
  </b-card>
</template>

<script>
import { ToggleList } from "@/components";
import { Switch } from "@/components";
import {mapActions} from "vuex";

export default {
  components: {
    [ToggleList.name]: ToggleList,
    [Switch.name]: Switch,
  },
  props: {
    platforms: {
      type: Object,
      required: true,
    },
    gatewayState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      help_text: "Select platforms that will be enabled for the notifications:",
      isInitDone: false,
      mutablePlatforms: null,
    };
  },
  created() {
    this.mutablePlatforms = _.cloneDeep(this.platforms);
  },
  methods: {
    ...mapActions('settings', ['setIsFormDirty']),
    togglePlatform(isEnabled, platName) {
      this.mutablePlatforms[platName].enabled = isEnabled;
      this.setIsFormDirty(true);
      this.$store.commit({
        type: "ispServices/notifications/setNotificationsPlatforms",
        platforms: this.mutablePlatforms,
      });
      if( platName == "push_app" ){
        this.$store.commit({ 
          type: "ispServices/notifications/updateNotificationsAdvancedConfiguration", 
          serviceName: "push_app", 
          subServiceName: "enabled",
          subServiceAdvancedConf : isEnabled 
          
        });
      }
    },
  },
  computed: {
    platformsList: {
      get() {
        const list = _.cloneDeep(this.mutablePlatforms);        
        delete list.push_web;        
        const papp = list.push_app;
        delete list.push_app         
                
        for (const key in this.gatewayState) {
          if (!this.gatewayState[key]) {
            list[key].enabled = false;
          }
        }
        
        list["push_app"] = papp;
        return list;
      },
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.card {
  margin-bottom: 0;
}
.platform-name__sms {
  text-transform: uppercase !important;
}
</style>

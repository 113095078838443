<template>
  <b-card class="px-4 pb-0">
    <template v-slot:header>
      <div class="d-flex align-items-center justify-content-between">
        <h4 class="text-capitalize mb-0 title mr-4" style="display: flex;">
          <svgicon
            class="icon mr-2"
            :icon="icons[serviceName] || 'item-passwordpolicy'"
            width="17"
            height="17"
            style="margin-top: 1px;"
            />
          <span>{{ t(translateHandler(serviceName, "_", " ")) }}</span>
        </h4>
        <div class="d-flex align-items-center">
          <div @click="onToggleCollaps" class="button d-flex">
            <n-button
              type="neutral"
              size="sm"
              block
              v-text="isCollapsed ? $t('Collapse - ') : $t('Expand + ')"
            />
          </div>
          <div class="d-flex ml-4">
            <p class="text-capitalize mr-3 mb-0">{{t('enabled')}}:</p>
            <n-switch
              class="mt-0 mb-0"
              :visible_for_permissions='[{actions: ["PUT"], path: "/notifications/.*"}]'
              v-model="mutableAllAdvancedConf[serviceName].send_notifications"
              :service="serviceName"
              color="allot"
            />
          </div>
        </div>
      </div>
    </template>
    <el-collapse-item :name="serviceName">
      <p style="position: relative; bottom: 5px" class="mb-4">
        {{ t(helpTexts[serviceName]) }}
      </p>
      <div class="row mt-2">
        <div
          :key="idx"
          v-for="(defualtSubService, subServiceName, idx) in defaultSubServices"
          class="col-6 mt-2 mb-3"
        >
          <b-card class="col-12 border-box full-height-box">
            <!-- @onResetSubService="onResetSubService" -->
            <SubService
              @onAdvacnedConfUpdate="onAdvacnedConfUpdate"
              :serviceName="serviceName"
              :subServiceName="subServiceName"
              :advancedConf="
                mutableAllAdvancedConf[serviceName][subServiceName]
              "
              :showB2B="mutableAllAdvancedConf.b2b_notifications_enabled"
            ></SubService>
          </b-card>
        </div>
      </div>
    </el-collapse-item>
  </b-card>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import { Switch } from "@/components";
import SubService from "@/components/GeneralManagement/Notifications/SubService";
import General from "@/mixins/General";
import authorization from "@/mixins/authorization";

export default {
  name: "Service",
  components: {
    [Switch.name]: Switch,
    SubService,
  },
  props: {
    defaultService: {
      type: Object,
      requried: true,
    },
    serviceName: {
      type: String,
      requried: true,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  visibility_by_product: {
    'blocked_url': '',
    'quiet_time_ended': ['CBEndpoint', 'DNSecure'],
    'quiet_time_released': 'CBEndpoint',
    'quiet_time_started': ['CBEndpoint', 'DNSecure'],
    'antibotnet': ['NetworkSecure', 'EndpointSecure', 'DNSecure'],
    'antimalware_category': ['NetworkSecure', 'EndpointSecure', 'DNSecure'],
    'antiphishing': ['NetworkSecure', 'EndpointSecure', 'DNSecure'],
    'antivirus': ['NetworkSecure', 'EndpointSecure'],
    'geo_fence_alert': 'CBEndpoint',
    'harmful_conversation': 'CBEndpoint',
    'battery_low': 'CBEndpoint',
    'battery_recovered': 'CBEndpoint',
    'device_connection_regained': 'CBEndpoint',
    'device_removal_confirmation': 'CBEndpoint',
    'device_app_lost_privileges': 'CBEndpoint',
    'device_data_changed': 'CBEndpoint',
    'device_deletion_forced': 'CBEndpoint',
    'internet_availability_long': ['CBEndpoint', 'EndpointSecure'],
    'internet_availability_short': 'CBEndpoint',
    'new_device_activated': 'CBEndpoint',
  },
  mixins: [General, authorization],
  data() {
    return {
      isInitDone: false,
      changingAdvancedConf: false,
      mutableAllAdvancedConf: {},
      helpTexts: {
        ads_free: "",
        anti_bullying:
          "Configure the notification in case of a harmful conversation is detected.",
        general: "",
        location_management:
          "Configure the notification in case the GeoFence policy is violated.",
        parental_control:
          "Configure the notification in case an URL was blocked.",
        threat_protection:
          "Configure the notification in case of a phishing attack, malware URL, file infected or botnet is detected.",
      },
      icons: {
        ads_free: 'item-adsfree',
        anti_bullying: 'item-bullingprotection',
        general: 'item-general',
        location_management: 'item-locationmanagement',
        parental_control: 'item-contentfilter',
        threat_protection: 'item-threatprotection',
      },
    };
  },
  created() {
    this.mutableAllAdvancedConf = _.cloneDeep(this.advancedConfiguration);

    setTimeout(() => {
      this.isInitDone = true;
    }, 300);
  },
  methods: {
    ...mapActions('settings', ['setIsFormDirty']),
    onToggleCollaps() {
      this.$emit("onToggleCollapse", this.serviceName);
    },
    onAdvacnedConfUpdate(subServiceName, subServiceAdvancedData) {
      this.changingAdvancedConf = true;

      this.mutableAllAdvancedConf[this.serviceName][subServiceName] = {
        ...this.mutableAllAdvancedConf[this.serviceName][subServiceName],
        ..._.cloneDeep(subServiceAdvancedData),
      };

      this.$store.commit({
        type: "ispServices/notifications/updateNotificationsAdvancedConfiguration",
        serviceName: this.serviceName,
        subServiceName: subServiceName,
        subServiceAdvancedConf: this.mutableAllAdvancedConf[this.serviceName][
          subServiceName
        ],
      });

      setTimeout(() => {
        this.changingAdvancedConf = false;
      }, 300);
    },
    // onResetSubService(subServiceName) {
    //   this.changingAdvancedConf = true;
    //   this.mutableAllAdvancedConf[this.serviceName][
    //     subServiceName
    //   ] = _.cloneDeep(
    //     this.defaultAdvancedConfiguration[this.serviceName][subServiceName]
    //   );

    //   setTimeout(() => {
    //     this.changingAdvancedConf = false;
    //   }, 300);
    // },
  },
  computed: {
    ...mapGetters("networksecure/status", ["networkSecureSnifferMode"]),
    ...mapGetters("settings", ["EShasAV"]),
    defaultSubServices() {
      let defaultService = _.cloneDeep(this.defaultService);

      delete defaultService.router_dns_setting_changed
      delete defaultService.router_dns_setting_reset
      delete defaultService.send_notifications;

      if (this.networkSecureSnifferMode && !this.EShasAV && defaultService.hasOwnProperty('antivirus')) {
        delete defaultService.antivirus;
      }

      defaultService = Object.keys(defaultService)
            .filter(key => this.checkProducts(this.$options.visibility_by_product[key] || ''))
            .reduce((obj, key) => {
                obj[key] = defaultService[key];
                return obj;
              }, {});

      return defaultService;


    },
    ...mapState("ispServices/notifications", [
      "advancedConfiguration",
      "defaultAdvancedConfiguration",
    ]),
  },
  watch: {
    mutableAllAdvancedConf: {
      handler: function (allAdvancedData) {
        if (this.isInitDone) {
          if (
            !allAdvancedData[this.serviceName].send_notifications &&
            !this.changingAdvancedConf
          ) {
            const forceClosed = true;
            this.$emit("onToggleCollapse", this.serviceName, forceClosed);
          }
          const serviceAdvancedConf = _.cloneDeep(
            allAdvancedData[this.serviceName]
          );
          this.setIsFormDirty(true);
          this.$store.commit({
            type: "ispServices/notifications/updateNotificationsAdvancedConfiguration",
            serviceName: this.serviceName,
            subServiceAdvancedConf: serviceAdvancedConf,
          });
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
  .bottom-border {
    width: 100%;
    background-color: black;
  }
  .card {
    margin-bottom: 10px;
  }
  .card-header {
    padding-top: unset;
  }
  .card-body {
    padding: 0 15px;
    margin: 0;
  }
  .full-height-box {
    height: 100%;
  }
</style>

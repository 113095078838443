<template>
  <div>
    <el-collapse v-model="mutableExpandedServices">
      <div v-for="serviceName in displayedServices" :key="serviceName">
        <Service
          v-if="defaultServices[serviceName]"
          @onToggleCollapse="onToggleCollapse"
          :defaultService="defaultServices[serviceName]"
          :serviceName="serviceName"
          :isCollapsed="mutableExpandedServices.includes(serviceName)"
        ></Service>
      </div>
    </el-collapse>
  </div>
</template>

<script>
import Service from "@/components/GeneralManagement/Notifications/Service";
import General from "@/mixins/General";
import authorization from "@/mixins/authorization";
import { mapGetters } from "vuex";

export default {
  name: "ServicesList",
  components: {
    Service,
  },
  props: {
    defaultServices: {
      type: Object,
      required: true,
    },
  },
  mixins: [General, authorization],
  data() {
    return {
      mutableExpandedServices: [],
    };
  },
  methods: {
    onToggleCollapse(serviceName, forceClose = false) {
      if (forceClose || this.mutableExpandedServices.includes(serviceName)) {
        this.mutableExpandedServices = this.mutableExpandedServices.filter(
          (srvcName) => srvcName !== serviceName
        );
      } else {
        this.mutableExpandedServices.push(serviceName);
      }
    },
  },
  computed: {
    ...mapGetters("settings",["getSubSystemsServicesId"]),

    displayedServices() {
      const displayedList = _.cloneDeep(this.getSubSystemsServicesId);
      if ( this.checkProducts( ['EndpointSecure', 'CBEndpoint', 'RouterSecure']))
        displayedList.push("general");
      return displayedList;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-collapse-item__wrap {
  border-bottom: unset;
}
.card-body {
  display: flex;
}
::v-deep .el-collapse-item__arrow {
  display: none;
}
::v-deep div[role="tab"] {
  display: none;
}

::v-deep .el-collapse-item__wrap {
  background-color: unset;
  border-bottom: unset;
}
::v-deep .el-collapse {
  border-top: unset;
  border-bottom: unset;
}
</style>

<template>
  <div v-if="!loading">
    <div class="mx-0 row justify-content-between">
      <h5 class="mb-0 capitalize font-weight-bold">
        {{ displaySubServiceName }}
      </h5>
      <n-switch
        class="mt-0 mb-0"
        v-model="mutableAdvancedConf.enabled"
        :visible_for_permissions='[{actions: ["PUT"], path: "/notifications/.*"}]'
        color="allot"
      />
    </div>
    <hr />
    <div class="block mb-2">
      <div class="d-flex flex-column justify-content-between">
        <h6 class="title mb-0">{{t('Minimum time between notifications')}}</h6>
        <div
          style="display: flex"
          class="flex justify-content-between align-items-center"
        >
          <el-slider
            style="width: 85%; color: red"
            rang
            :max="Object.keys(sliderOptions).length - 1"
            :format-tooltip="formatTooltip"
            v-model="sliderVal"
          ></el-slider>
          <p>{{ t(sliderMinuteValue) }}</p>
        </div>
      </div>
    </div>
    <Messages
      @onMsgUpdate="onMsgUpdate"
      @onAdvacnedConfUpdate="onAdvacnedConfUpdate"
      :serviceName="serviceName"
      :subServiceName="subServiceName"
      :advancedData="advancedConf"
      :showB2B="showB2B"
      ref="messages"
    ></Messages>
    <BasicModal
      @onFirstBtn="onReset"
      @onSecBtn="showResetAlertModal = false"
      :firstBtnTxt="this.t('Reset')"
      :secBtnTxt="this.t('Cancel')"
      :txt="ResetTxtModal"
      :showModal="showResetAlertModal"
      :maxWidth="450"
    ></BasicModal>
    <div class="d-flex justify-content-center">
      <n-button
        @click.native="showResetAlertModal = true"
        style="width: 120px"
        type="primary"
        size="md"
        round
        :visible_for_permissions='[{actions: ["PATCH"], path: "/notifications/languages-configuration/.*"}]'
        block
        v-text="getLocalizedText('Reset')"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Messages from "@/components/GeneralManagement/Notifications/Messages";
import { Slider } from "element-ui";
import { Switch } from "@/components";
import General from "@/mixins/General";
import { SLIDER_OPTIONS } from "@/configs/generalManagement";
import BasicModal from "@/components/Modals/BasicModal";
import {translateHandler} from "@/util/util";

export default {
  name: "SubService",
  components: {
    Messages,
    Slider,
    [Switch.name]: Switch,
    BasicModal,
  },
  props: {
    serviceName: {
      type: String,
      requried: true,
    },
    subServiceName: {
      type: String,
      requried: true,
    },
    advancedConf: {
      type: Object,
      defualt: () => null,
    },
    showB2B: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [General],
  data() {
    return {
      loading: false,
      mutableAdvancedConf: null,
      originalSliderVal: 1,
      sliderVal: 1,
      sliderOptions: SLIDER_OPTIONS,
      initDone: false,
      showResetAlertModal: false,
      ResetTxtModal:
        this.t('Are you sure you want to reset this specific notification by loading the default ones?'),
    };
  },
  created() {
    this.mutableAdvancedConf = _.cloneDeep(this.advancedConf);
    this.sliderVal = this.originalSliderVal = Object.keys(this.sliderOptions).indexOf(
      `${this.mutableAdvancedConf.min_interval}`
    );

    setTimeout(() => {
      this.initDone = true;
    }, 300);
  },
  computed: {
    sliderMinuteValue() {
      return Object.values(this.sliderOptions)[this.sliderVal];
    },
    ...mapState("ispServices/notifications", [
      "defaultsNotifications",
      "defaultLang",
      "selectedLang",
      "defaultAdvancedConfiguration",
    ]),
    displaySubServiceName() {
      return this.$t(translateHandler(this.subServiceName, "_", " "));
    }
  },
  methods: {
    ...mapActions("ispServices/notifications", ["resetLanguage"]),
    ...mapActions('settings', ['setIsFormDirty']),
    formatTooltip(val) {
      return this.t(Object.values(this.sliderOptions)[val]);
    },
    onMsgUpdate(msgs) {
      this.setIsFormDirty(true);
      this.$store.commit({
        type: "ispServices/notifications/updateNotificationsConfiguration",
        serviceName: this.serviceName,
        subServiceName: this.subServiceName,
        subService: _.cloneDeep(msgs),
      });
    },
    onAdvacnedConfUpdate(subServiceName, advancedData) {
      for (const key in advancedData) {
        this.mutableAdvancedConf[key] = advancedData[key];
      }
      this.$emit("onAdvacnedConfUpdate", subServiceName, advancedData);
    },
    onReset() {
      this.loading = true;
      this.showResetAlertModal = false;
      this.resetLanguage({
        all: false,
        serviceName: this.serviceName,
        subServiceName: this.subServiceName,
      });
      this.$store.commit({
        type: "ispServices/notifications/resetSubServiceNotificationConfiguration",
        serviceName: this.serviceName,
        subServiceName: this.subServiceName,
      });
      this.sliderVal = this.originalSliderVal;
      this.$refs.messages.reset();
      this.loading = false;
    },
    getLocalizedText(text) {
      return this.t(text);
    }
  },
  watch: {
    mutableAdvancedConf: {
      handler: function (advancedConf) {
        if (this.initDone) {
          this.$emit("onAdvacnedConfUpdate", this.subServiceName, advancedConf);
        }
      },
      deep: true,
    },
    sliderVal: function (val) {
      this.mutableAdvancedConf.min_interval = +Object.keys(this.sliderOptions)[
        val
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-slider__bar {
  background-color: #f96332;
}
::v-deep .el-slider__button {
  border-color: #f96332;
}

.card-body {
  padding: 0 20px;
}
</style>
